import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper, Divider,Button, Link } from "@material-ui/core";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import { GUID, numberWithCommas } from "../../dwi-functions";
import { makeStyles } from "@material-ui/core/styles";
import targets from "../../settings";
import hero from '../../images/243c5983-e932-435d-9798-3aa696bcdf8f.jpg';
import { maxWidth } from "@material-ui/system";
import { API_URL } from '../../constants'
var _ = require("lodash");

const useStyles = makeStyles((o) => ({
  root: { 
     minWidth: 275,
    margin:"auto",
    maxWidth: '1200px'
 },
 body:{
     fontSize: '18px',
     marginTop: "1em",
     marginBottom: "1em",
     maxWidth: "900px",
     margin:"auto"
 },
  appBar: {
    padding: "0 1em",
    paddingBottom: "1em",
    textAlign: "center",
    background: "rgba(2,45,131,1)",
  },
  title: { fontSize: 14 },
  pos: { marginBottom: 12 },
  paper: {
    backgroundColor: "#efefef",
    border: "1px solid #ECECEC",
    boxShadow: o.shadows[5],
    padding: o.spacing(1),
  },
  mattable: { marginBottom: "1em" },
  compliance: {
    backgroundColor: "#53d64d",
  },
  button:{
      borderRadius: '50px',
      padding: '1em',
      background:'#4556a6',
      color:'white',
      margin:'auto',
      display:'block',
      maxWidth: '300px',
      textAlign: 'center',
      textDecoration:'none'
  },
  hero:{
    width: "100%",
    height: "auto",

    margin: 'auto'
  },
  root2:{
    padding: '0 1em',
    margin:'auto',
    maxWidth: '1200px'
  }
}));

const LightCalculated = (props) => {
  const [hasError, setErrors] = useState(false);
  const [cards, setCards] = useState([]);
  const [SelectRef, setSelectRef] = useState();
  const [loading, setLoading] = useState([true]);
  const [totals, setTotals] = useState([
    { racks: 0, lbs: 0, gwpContribution: 0, wagwp: 0, target: 0 },
  ]);
  const [guid, setGuid] = useState(GUID);
  const classes = useStyles();
  const [compliance2025, setCompliance2025] = useState("Compliant");
  const [compliance2030, setCompliance2030] = useState("Compliant");
  const [compliantClass2025, setCompliantClass2025] = useState("#53d64d");
  const [compliantClass2030, setCompliantClass2030] = useState("#53d64d");
  const [contactMessage, setMessage] = useState(true);

  function setCompliance() {
    var currentWAGWP = totals[0].wagwp;

    if (currentWAGWP <= targets.gwpTarget.twentytwentyfive) {
      setCompliance2025("Compliant");
      setCompliantClass2025("#53d64d");
      setMessage(true);
    } else {
      setCompliance2025("Non-compliant");
      setCompliantClass2025("#e6c6c5");
      setMessage(false);
      //return <Paper>Contact Info</Paper>;
    }

    if (currentWAGWP <= targets.gwpTarget.twentythirty) {
      setCompliance2030("Compliant");
      setCompliantClass2030("#53d64d");
      setMessage(true);
    } else {
      setCompliance2030("Non-compliant");
      setCompliantClass2030("#e6c6c5");
      setMessage(false);
      //return <Paper>Contact Info</Paper>;
    }
  }

  async function fetchSelectRef() {
    const res = await fetch(
      API_URL + "refrigerants/select"
    );
    res.json().then((res) => {
      _.sortBy(res, ["name"]);
      setSelectRef(res);
    });
    // console.log(res.json());
  }

  const sr = {};
  var sortedObj = [];
  if (SelectRef) {
    SelectRef.map((client) => {
      const { name, gwp_value } = client;
      sr[ name ] = name;
      sortedObj[name] = gwp_value;
    });
    // console.log(sortedObj);
  }

  useEffect(() => {
    var project = {
      projectId: guid,
      refrigerant: "R744",
      racks: 0,
      lbs: 0,
      gwpContribution: 0,
    };
    var e = [];
    e.push(project);
    // setCards(e);
    fetchSelectRef();
    setLoading(false);
    //setCompliance();
    //  updateTotals(e);
    //setTotals({racks:0,lbs:0,gwpContribution:0,wagwp:0,target:0});
    // console.log(cards);
  }, []);

  return (
    <div className={style} >
        <Grid container spacing={0} className={classes.root}>
            <Grid item lg={12} sm={12} xs={12}>
                
            <img src={hero} alt="GWP Calculator" className={classes.hero} />
           <div className={classes.root2}>   
                <Typography variant="h4" align="center">Use this calculator to determine your company compliance with CARB refrigeration regulations.</Typography>
                <Typography variant="body1" className={classes.body}>Start by clicking the lower right “+” button. Then select your operation’s existing refrigerant type from the dropdown menu, add the number of racks used, lbs. of refrigerant and finally, the GWP contribution for the refrigerant. If your operation utilizes multiple refrigerants, continue adding those refrigerants by clicking the “+” button until completed.</Typography>
                <Typography variant="body1" className={classes.body}>The gray boxes show the category totals. If the bottom boxes are green, then you are compliant with CARB regulations. If they are red, then you need to act and Hillphoenix is here to help. Contact us today.</Typography>
                </div>  
            </Grid>
        </Grid>

      <Grid container spacing={2} className={classes.root2}>
        <Grid item xl={3} sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography>Total Racks</Typography>
            <Typography>{totals[0].racks}</Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography>Total Lbs</Typography>
            <Typography>{numberWithCommas(totals[0].lbs)}</Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography>Total GWP</Typography>
            <Typography>
              {numberWithCommas(totals[0].gwpContribution)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography>WAGWP</Typography>
            <Typography>{(totals[0].wagwp * 1).toFixed(0)}</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid className={classes.root2} container spacing={2}>
        <Grid item xl={12} sm={12} xs={12}>
      <MaterialTable
        className={classes.root}
        isLoading={loading}
        hasError={hasError}
        data={cards}
        title={<Typography>Calculated GWP</Typography>}
        options={{
          search: false,
          pageSize: 8,
          paging: false,
          showTitle: false,
          headerStyle: {
            backgroundColor: '#474546',
            color: '#FFF'
          }
          //  headerStyle: {backgroundColor: "#5c74ab"},
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                newData["projectId"] = guid;
                var d = [];

                _.forEach(cards, function (v, k) {
                  v.gwpContribution = _.toNumber(v.gwpContribution);
                  d.push(v);
                });
                let selected = newData["refrigerant"];
                selected = sortedObj[newData["refrigerant"]];
                newData["gwpContribution"] = newData["lbs"] * selected;

                d.push(newData);

                //   console.log(newData);
                var e = _.sortBy(d, ["gwpContribution"]);
                totals[0].racks = _.sumBy(d, function (n) {
                  return n.racks;
                });
                totals[0].gwpContribution = _.sumBy(d, function (n) {
                  return n.gwpContribution;
                });
                totals[0].lbs = _.sumBy(d, function (n) {
                  return n.lbs;
                });
                totals[0].wagwp = _.sumBy(d, function (n) {
                  return n.gwpContribution / n.lbs;
                });
                e = _.reverse(e);
                setCards(e);
                // updateTotals(e);
                setCompliance();
                resolve();
              }, 500);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...cards];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setCards(dataDelete);
                //console.log(dataDelete);
                var dd = [
                  {
                    racks: _.sumBy(dataDelete, function (e) {
                      return e.racks;
                    }),
                    gwpContribution: _.sumBy(dataDelete, function (n) {
                      return n.gwpContribution;
                    }),
                    lbs: _.sumBy(dataDelete, function (n) {
                      return n.lbs;
                    }),
                    wagwp: _.sumBy(dataDelete, function (n) {
                      return n.gwpContribution / n.lbs;
                    }),
                  },
                ];
                //console.log(dd);
                setTotals(dd);
                setCompliance();
                resolve();
              }, 500);
            }),
        }}
        columns={[
          { title: "projectId", field: "projectId", hidden: true },
          { title: "Refrigerant", field: "refrigerant", lookup: sr },
          { title: "Racks", field: "racks", type: "numeric" },
          {
            title: "LBS",
            field: "lbs",
            type: "numeric",
            render: (rowData) => (
              <Typography>{numberWithCommas(parseInt(rowData.lbs))}</Typography>
            ),
          },
          {
            title: "GWP Contribution",
            field: "gwpContribution",
            type: "numeric",
            render: (rowData) => (
              <Typography>
                {numberWithCommas(parseInt(rowData.gwpContribution))}
              </Typography>
            ),
          },
          {
            title: "Contribution",
            type: "numeric",
            render: (rowData) => (
              <Typography>
                {(
                  (parseInt(rowData.gwpContribution) /
                    parseInt(totals[0].gwpContribution)) *
                  100
                ).toFixed(0)}
                %
              </Typography>
            ),
          },
        ]}
      />
</Grid></Grid>


      <Grid className={classes.root2} container spacing={3}>
        
        <Grid item xl={6} lg={6} sm={6} xs={12}>
          <Paper
            style={{ backgroundColor: compliantClass2025 }}
            className={classes.paper}
          >
            2025 Target: {targets.gwpTarget.twentytwentyfive} - {compliance2025}
            <Divider></Divider>
            <Typography hidden={contactMessage} variant="h5">
            Derek Gosselin, Director of Technical Product Support <Link href="mailto:dgosselin@doverfoodretail.com">dgosselin@doverfoodretail.com</Link>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={6} lg={6} sm={6} xs={12}>
          <Paper
            style={{ backgroundColor: compliantClass2030 }}
            className={classes.paper}
          >
            2030 Target: {targets.gwpTarget.twentythirty} - {compliance2030}
            <Divider></Divider>
            <Typography hidden={contactMessage} variant="h5">
            Derek Gosselin, Director of Technical Product Support <Link href="mailto:dgosselin@doverfoodretail.com">dgosselin@doverfoodretail.com</Link>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xl={12} lg={12} sm={12} xs={12}>
            <Link className={classes.button} href="mailto:dgosselin@doverfoodretail.com">I need help or more info</Link>
            <Typography variant="body1" className={classes.body}>
              For more information click the link above or contact Derek Gosselin, Director of Technical Product Support at  <Link href="mailto:dgosselin@doverfoodretail.com">dgosselin@doverfoodretail.com</Link> or call 678-372-4111
            </Typography>
        </Grid>


      </Grid>
    </div>
  );
};

LightCalculated.propTypes = {};

export default LightCalculated;
