import React, { useState, useEffect } from 'react';
import { Divider, Grid } from '@material-ui/core';
import NavBreadCrumb from '../../NavBreadCrumb';
import MaterialTable from 'material-table'
import {getCookie ,GUID } from '../../dwi-functions';
import { API_URL } from '../../constants'


const Refrigerants = (props) => {

    const [hasError, setErrors] = useState(false);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState([true]);


    async function fetchData(id) {
        const res = await fetch(API_URL + "refrigerants/");
       // console.log(res);
        res
            .json()
            .then(res => setCards(res))
            .then(res => setLoading(false))
            .catch(err => setErrors(true));
    }

    async function postData(url, data) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          body: data, // body data type must match "Content-Type" header,
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        return response.json(); // parses JSON response into native JavaScript objects
      }

    

    useEffect(() => {
        fetchData(getCookie('_au'));
    }, []);



    return (
        <div>
            <NavBreadCrumb></NavBreadCrumb>
            <Divider></Divider>
<Grid container >
  <Grid item lg={12}>
  <MaterialTable
        isLoading={loading}
        hasError={hasError}
        columns={[
            { title: 'pkRecID', field: 'pkRecID', hidden: true },
            { title: 'Refrigerants Name', field: 'name' },
            { title: 'Refrigerants Type', field: 'type' },
            { title: 'GWP Value', field: 'gwpValue' }
        ]}
        data={cards}
        editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                const data = {
                    name: newData.name,
                    gwpValue: newData.gwpValue,
                    type: newData.type,
                    pkRecId: GUID()
                };
                console.log(JSON.stringify(data));
                const u = API_URL + 'refrigerant/add/';
                const res = postData(u,JSON.stringify(data));
                
                  //fetchData(getCookie('_au'));
                  resolve();
                 window.location.reload();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const index = oldData.pkRecId;
                const u = API_URL +'refrigerant/add/';
                const res = fetch(u);
                  fetchData(getCookie('_au'));
                  resolve()
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
              const index = oldData.pkRecId;
              const u = API_URL +'refrigerant/delete/'+index;
              const res = fetch(u);
                fetchData(getCookie('_au'));
                resolve()
              }),
          }}
        title="Refrigerants"

      />
  </Grid>
</Grid>
            




        </div>
    );
};

Refrigerants.propTypes = {

};

export default Refrigerants;