import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../theme.json';
import appVars from '../appVars.json';
import { setCookie } from '../dwi-functions';
import logo from '../logo.png';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import { API_URL } from '../constants'

var log = require('loglevel');


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://digitalwerksinnovations.com/">
                {appVars.copyright}
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw'
    },
    image: {
        backgroundImage: 'url(background.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        maxWidth: '300px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
      rightside:{
        //background:themeFile.palette.appBar.secondary
      }
}));

export default function SignInSide() {
    const classes = useStyles();
    const theme = createMuiTheme(themeFile);
    const [hasError, setErrors] = useState(false);
    const [open, setOpen] = useState(false);
    //const bcrypt = require('bcryptjs');
    //const { Users} = require('../sequelize')
    
    async function postData(url, data) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          body: data, // body data type must match "Content-Type" header,
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        return response.json(); // parses JSON response into native JavaScript objects
      }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setErrors(false);
    };




    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} xl={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} xl={5} component={Paper} className={classes.rightside} elevation={6} square>
                    <div className={classes.paper}>
                        <img src={logo} className={classes.logo} alt="DigitalWerks Logo"/>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => {
                                    
                                    setOpen(true);
                                    const formData = {
                                        "passwd": document.getElementById('password').value,
                                        "email": document.getElementById('email').value
                                      };


                                    postData(API_URL +'validate', JSON.stringify(formData))
                                        .then(data => {
                                            if (data.status === 200 && data.uid.length === 36) {
                                                log.warn("Login was a success");

                                                setCookie('_au', data.uid, 7);
                                               
                                                //                                                console.log(data);
                                                setTimeout(()=>{  setOpen(false);window.location = "/";},3000);
                                               
                                            } else {
                                                log.error("Login was NOT a success");
                                                setErrors(true);
                                            }
                                        }); 
                                }}
                            >
                                Sign In
            </Button>

                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/register" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}  >
                <Alert onClose={handleClose} severity="error">
                    Your email or password is incorrect, I can't let you in. 403: Access Denied!
                </Alert>
            </Snackbar>

                                <Backdrop open={open} className={classes.backdrop}>
                                <CircularProgress size={48}  />
                                </Backdrop>

        </ThemeProvider>
    );
}