import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button
} from "@material-ui/core";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import {  numberWithCommas } from "../../../dwi-functions";
import { makeStyles } from "@material-ui/core/styles";
import { API_URL } from '../../../constants'
var _ = require("lodash");


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  appBar: {
    padding: "0 1em",
    paddingBottom: "1em",
    textAlign: "center",
    background: "rgba(2,45,131,1)"
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    backgroundColor: "#efefef",
    border: "1px solid #ECECEC",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    fontSize: '14px'
  },
  mattable: {
    marginBottom: "1em"
  }
}));

const GwpCalculation = (props) => {
  const [hasError, setErrors] = useState(false);
  const [cards, setCards] = useState([]);
  const [SelectRef, setSelectRef] = useState();
  const [loading, setLoading] = useState([true]);
  const [totals, setTotals] = useState([
    {
      racks: 0,
      lbs: 0,
      gwpContribution: 0,
      wagwp: 0,
      target: 0,
    },
  ]);

  const classes = useStyles();

  async function fetchData(id) {
    const res = await fetch(API_URL + "calculatedgwp/" + id);
    // console.log(res);
    res
      .json()
      .then((res) => {
        res.push({
          projectId: id,
          refrigerant: 'R744',
          racks:0,
          lbs: 0,
          gwpContribution: 0
        });
        
          var d=[];
          _.forEach(res,function(v,k){
            v.gwpContribution = _.toNumber(v.gwpContribution);
            d.push(v);  
          });
        //console.log(d);
        var e =_.sortBy(d, ["gwpContribution"]);
        e= _.reverse(e);
        setCards(e);
        //console.log(e);
      })
      .then((res) => setLoading(false))
      .catch((err) => setErrors(true));

    const res2 = await fetch(API_URL + "calculatedgwptotals/" + id);

    if (res2.status === 200) {
     // console.log(res2);
      res2
        .json()
        .then((res2) => setTotals(res2))
        .then((res) => setLoading(false))
        .catch((err) => setErrors(true));
    }


  }

  useEffect(() => {
    fetchData(props.pid);
    //console.log(cards);
  }, []);
  return (
    <div className={style}>
      <Grid className={classes.mattable} container spacing={3}>
      <Grid item lg={12} >
      <Button className={classes.refresh} variant="outlined" size="large" color="default" onClick={() => window.location.reload()}>Reset</Button> 
      </Grid>
        <Grid item lg={3}>
          <Paper>
            <Typography className={classes.paper}>Total Racks: {totals[0].racks}</Typography>

          </Paper>
        </Grid>
        <Grid item lg={3}>
          <Paper>
            <Typography className={classes.paper}>Total Lbs: {numberWithCommas(totals[0].lbs)}</Typography>
          </Paper>
        </Grid>
        <Grid item lg={3}>
          <Paper>
            <Typography className={classes.paper}>Total GWP: {numberWithCommas(totals[0].gwpContribution)}</Typography>

          </Paper>
        </Grid>
        <Grid item lg={3}>
          <Paper >
            <Typography className={classes.paper}>WAGWP: {(totals[0].wagwp *1).toFixed(0)}</Typography>

          </Paper>
        </Grid>
      </Grid>


      <MaterialTable
        isLoading={loading}
        hasError={hasError}
        data={cards}
        title={<Typography>Calculated GWP</Typography>}
        options={{
          search: false,
          pageSize: 8,
          paging: false,
          showTitle: false,

          //  headerStyle: {backgroundColor: "#5c74ab"},
        }}
        columns={[
          { title: "projectId", field: "projectId", hidden: true },
          { title: "Refrigerant", field: "refrigerant" },
          { title: "Racks", field: "racks", type: "numeric" },
          {
            title: "LBS",
            field: "lbs",
            type: "numeric",
            render: (rowData) => (
              <Typography>{numberWithCommas(parseInt(rowData.lbs))}</Typography>
            ),
          },
          {
            title: "GWP Contribution",
            field: "gwpContribution",
            type: "numeric",
            render: (rowData) => (
              <Typography>
                {numberWithCommas(parseInt(rowData.gwpContribution))}
              </Typography>
            ),
          },
          {
            title: "Contribution",
            type: "numeric",
            render: (rowData) => (
              <Typography>
                {(parseInt(rowData.gwpContribution) / parseInt(totals[0].gwpContribution) * 100).toFixed(0)}%
              </Typography>
            ),
          },
        ]}
      />
    </div>
  );
};

GwpCalculation.propTypes = {};

export default GwpCalculation;
