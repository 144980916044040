const DATABASE_NAME = 'gwpapi';
const USERNAME = 'root';
const PASSWORD = '';
const HOST = '127.0.0.1';
const DIALECT = 'mysql';
const API_URL = 'http://127.0.0.1:3001/';//'https://gwpapi.cvcdevserver.com/'; // with trailing slash

module.exports = {
    DATABASE_NAME: DATABASE_NAME,
    USERNAME: USERNAME,
    PASSWORD: PASSWORD,
    HOST: HOST,
    DIALECT: DIALECT,
    gwpTarget: 1400,
    API_URL: API_URL
}




