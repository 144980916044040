import React from 'react';
import { Grid,Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import{getCookie} from '../dwi-functions';
import TextField from '@material-ui/core/TextField';
import { API_URL } from '../constants';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    head: {
        borderBottom: "1px solid"
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.light
    },
    paper: {
        padding: "1em"
    },
    divider: {
        marginBottom: "1em",
        marginTop: "1em"
    }
}));

async function postData(url, data) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: data, // body data type must match "Content-Type" header,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log(response);
    return response.json(); // parses JSON response into native JavaScript objects
  }



const AddProject = (props) => {

    const [processstatus, setProcessStatus] = React.useState(true);
    const [errors, setErrors] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <Grid container spacing={1} data-bool={processstatus} >

  <Input type="hidden" value={getCookie('_au')}></Input>  
  <Grid item xl={8} xs={12}>

        <TextField
          id="project_name"
          label="Project Name"
          style={{ margin: 8 }}
          placeholder="Project Name"
          helperText="Enter a name for your project."
          fullWidth
          margin="normal"
        /> 

            </Grid>
            <Grid item xl={2} xs={12}></Grid>
                <Grid item xl={1} xs={12}>
                    <Button color="default" variant="outlined" fullWidth={true}
                        onClick={()=>{
                            setOpen(true);
                            const formData = {
                              "name": document.getElementById('project_name').value,
                              "userid": getCookie('_au')
                            };
          
                           // console.log(JSON.stringify(formData));
          
          
                            postData(API_URL + 'projects/add', JSON.stringify(formData))
                              .then(data => {
                                //console.log(data.response);
                                if (data.id) {
                                   window.location.href = "/project/edit/"+data.projectId;
                                } else {
                                  setErrors(true);
                                }
                              });
                        }}
                    >Add</Button>
                </Grid>
                <Grid item xl={1} xs={12}>
                    <Button color="default" variant="outlined" fullWidth={true} href="/">Cancel</Button>
                </Grid>
            </Grid>
        
            
        </div>
    );
};

AddProject.propTypes = {

};

export default AddProject;
