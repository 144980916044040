import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, AppBar, Tabs, Tab, Typography,Button} from "@material-ui/core";
import ProjectDetail from "../projects/project_detail";
import ProjectDetailsUpload from "../projects/project_detail/ProjectDetailsUpload";
import GwpCalculation from "../projects/project_detail/GwpCalculation";
import GwpCalculationResults from "../projects/project_detail/GwpCalculationResults";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { API_URL } from '../constants'

function TabPanel(props) {  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const projectID = window.location.pathname.split("/").pop();
const url = API_URL + "project/" + projectID;
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  appBar: {
    padding: "0 1em",
    paddingBottom: "1em",
    textAlign: "center",
    background: "linear-gradient(90deg, rgba(2,45,131,1) 55%,  rgba(0,0,0,1) 100%)"
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
  },
  refresh:{
    color: "#000",
   float:"right",
    margin: theme.spacing(1)
  },
}));

const EditProject = (props) => {
  const [project, setProject] = useState([{ name: "" }]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function fetchData(id) {
    const res = await fetch(id);
    //console.log(res)
    res.json().then((res) => setProject(res));
  }

  useEffect(() => {
    fetchData(url);
  }, []);



  //console.log(project[0].name);

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
                    <Tab label="Calculated GWP" />
          <Tab label="Project Data" />


          <Tab label="Project Information" />
          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3} >
          <Grid item lg={6} xl={6}>
            <GwpCalculation pid={projectID}></GwpCalculation>
          </Grid>
          <Grid item lg={6} xl={6}>
            <GwpCalculationResults pid={projectID}></GwpCalculationResults>

          </Grid>
        </Grid>


      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProjectDetailsUpload pid={projectID}></ProjectDetailsUpload>
        <ProjectDetail pid={projectID}></ProjectDetail>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid container spacing={3}>
          <Grid item lg={4} xl={4}>
            <TextField
              id="project_name"
              label="Project Name"
              style={{ margin: 8 }}
              placeholder="Project Name"
              helperText="Name of your project."
              value={project[0].name}
              fullWidth
              margin="normal"
            />{" "}


            <TextField
              id="contact"
              label="Contact Name"
              style={{ margin: 8 }}
              placeholder="Contact Name"
              value={project[0].contact}
              fullWidth
              margin="normal"
            />


            <TextField
              id="phone"
              label="phone"
              style={{ margin: 8 }}
              placeholder="Phone"
              value={project[0].phone}
              fullWidth
              margin="normal"
            />

            <TextField
              id="email"
              label="Email"
              style={{ margin: 8 }}
              placeholder="Email"

              value={project[0].email}
              fullWidth
              margin="normal"
            />

            <TextField
              id="eor"
              label="Engineer of Record"
              style={{ margin: 8 }}
              placeholder="Engineer of Record"

              value={project[0].eor}
              fullWidth
              margin="normal"
            />

          </Grid>
        </Grid>

      </TabPanel>

    </div>
  );
};

EditProject.propTypes = {};

export default EditProject;
